<template>
  <!-- <header class="fixed top-0 left-0 right-0 z-10">
  <div class="flex items-center justify-center px-2 py-2 bg-cover bg-center bg-red-400">
      <van-icon name="arrow-left" size="25" @click="goback" />
      <span class="ml-1 text-base" @click="goback">返回</span>
      <span class="flex-1 text-center">{{ props.title }}</span>
  </div>
</header> -->


  <van-sticky>
    <van-nav-bar :title="props.title">
      <template #left>
        <div class="flex items-center  px-2 py-2 bg-cover bg-center">
          <template v-if="!props.hide">
            <van-icon name="arrow-left" size="25" @click="goback"/>
            <span class="ml-1 text-base" @click="goback">返回</span>
          </template>
        </div>
      </template>
      <template #right v-if="!props.hide">
        <router-link to="/">
          <van-icon name="wap-home" size="24"/>
        </router-link>
      </template>
    </van-nav-bar>
  </van-sticky>
</template>


<script setup>
import {ref, defineProps} from "vue";

const props = defineProps({
  title: {
    type: String,
    required: false
  },
  hide: {
    type: Boolean,
    required: false
  },
  transparent: {
    type: Boolean,
    required: false
  }
})
const goback = () => {
  window.history.go(-1)
}

</script>
